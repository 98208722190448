import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
  Link,
  FormErrorMessage,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useLayoutEffect, useState } from 'react';

function Layout(props) {
  const { children } = props;
  const [licenseKey, setLicenseKey] = useState(null);
  const [hasLicense, setHasLicense] = useState(true);
  const [notValid, setNotValid] = useState(false);

  useLayoutEffect(() => {
    setHasLicense(window.localStorage.getItem('license'));
  }, []);

  const onSubmit = async () => {
    const { data } = await axios.get(
      `/.netlify/functions/license?key=${licenseKey}`
    );
    setHasLicense(data.verified);

    if (data.verified) {
      window.localStorage.setItem('license', licenseKey);
    } else {
      window.localStorage.removeItem('license');
      setNotValid(true);
    }
  };

  return (
    <>
      <Modal isOpen={!hasLicense} onClose={() => {}}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Robust UI</ModalHeader>
          <ModalBody>
            <FormControl isRequired isInvalid={notValid}>
              <FormLabel>Gumroad License Key</FormLabel>
              <Input
                type="text"
                onChange={e => setLicenseKey(e.target.value)}
                placeholder="BXUSF-XFHY2-HDYF4"
              />
              <FormHelperText hidden={notValid}>
                The key you received after purchasing{' '}
                <Link href="https://gumroad.com/l/lWvh" target="_blank">
                  Robust UI
                </Link>{' '}
                on your email receipt
              </FormHelperText>
              <FormErrorMessage>
                <div>
                  The license key is not valid please contact me at{' '}
                  <Link href="mailto:sam@samdawson.dev">sam@samdawson.dev</Link>
                </div>
              </FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              isDisabled={!licenseKey}
              colorScheme="blue"
              onClick={onSubmit}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
        }}
      >
        {children}
      </div>
    </>
  );
}

export default Layout;
